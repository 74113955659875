import React, { Component } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import '../styles/keyboard.scss'
import drag_icon from "../images/drag.png";


class KeyboardComponent extends Component {

  constructor(props){

    super(props);

    this.state = { active: false }

  }

  componentDidMount(){

    let keyboardComponent = this;

    // Click event listener to detect when user enters input field
    window.addEventListener('click', (event) => {

      // User is interacting with keyboard
      if( event.target.closest("#primary-keyboard") !== null ){ return; }

      // If event.target is not an input we update the component state and return
      if( event.target.tagName !== "INPUT" ){ 
        keyboardComponent.setState({
          active: false,
          target_input: null
        })
        return; 
      }

      // Set keyboard input to the value at current input field
      this.keyboard.setInput(event.target.value);

      // Updates state of component
      keyboardComponent.setState({
        active: true,
        target_input: event.target
      })

      let keyboard_html = document.querySelector("#primary-keyboard");
      keyboard_html.style.top = (event.target.offsetTop + event.target.offsetHeight) +"px";
      
      if(event.path[0].id === "input_3"){
        keyboard_html.style.right = event.target.offsetRight+"px";
      }else{
        keyboard_html.style.left = event.target.offsetLeft+"px";
      }
      // Moving Keyboard html to input field


    }, true);

    // Allowing only move effect when dragging starts
    // document.querySelector("#primary-keyboard .drag-icon").addEventListener('dragstart', function(event){
    //   event.dataTransfer.effectAllowed = "move";
    // })

    // Drag Event on Keyboard
    // document.querySelector("#primary-keyboard .drag-icon").addEventListener('drag', function(event){
    //   event.preventDefault();

    //   if(!event.pageY || !event.pageX){ return; }

    //   event.dataTransfer.dropEffect = "move";

    //   let keyboard = document.querySelector("#primary-keyboard");
    //   keyboard.style.top = event.pageY + "px";
    //   keyboard.style.left = event.pageX + "px";
    // });

    //Dragover Event on Keyboard
    // document.querySelector("#primary-keyboard .drag-icon").addEventListener('dragover', function(event){
    //   event.preventDefault();
    //   event.dataTransfer.dropEffect = "move";
    //   let keyboard = document.querySelector("#primary-keyboard");
    //   keyboard.style.top = event.pageY + "px";
    //   keyboard.style.left = event.pageX + "px";
    // });

    // Drop Event on Keyboard
    // document.querySelector("#primary-keyboard .drag-icon").addEventListener('drop', function(event){
    //   event.preventDefault();
    //   event.dataTransfer.dropEffect = "move";
    // });

  }

  onChange = (input_text) => {

    let KeyboardComponent = this;

    let keyboard = this.keyboard;

    KeyboardComponent.state.target_input.value = input_text;
    
  }

  onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * Shift functionality
     */
    if (button === "{shift}") this.handleShift();

    /**
     * Caps functionality
     */
    if (button === "{lock}") this.handleCaps();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "shift" ? "default" : "shift"
    });
  };

  handleCaps = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "caps" ? "default" : "caps"
    });
  };
        // <div className="keyboard-header">
        //   <img
        //     className="drag-icon"
        //     src={drag_icon}
        //     alt="drag icon"
        //   />
        // </div>

  render(props){
    return (
      <div id = {this.props.id} data-active={this.state.active}>
        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          layoutName={this.state.layoutName}
          layout={{
            default: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              "{tab} q w e r t y u i o p [ ] \\",
              "{lock} a s d f g h j k l ; ' {enter}",
              "{shift} z x c v b n m , . / {shift}",
              ".com @ {space}"
            ],
            shift: [
              "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
              "{tab} Q W E R T Y U I O P { } |",
              '{lock} A S D F G H J K L : " {enter}',
              "{shift} Z X C V B N M < > ? {shift}",
              ".com @ {space}"
            ],
            caps: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              "{tab} Q W E R T Y U I O P [ ] \\",
              "{lock} A S D F G H J K L ; ' {enter}",
              "{shift} Z X C V B N M , . / {shift}",
              ".com @ {space}"
            ]
          }}
        />
      </div>
    );
  }
}
export default KeyboardComponent;