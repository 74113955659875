import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/contactForm'
import KeyboardComponent from '../components/Keyboard.js'
import '../styles/contact.scss'
import GravityFormForm from 'gatsby-plugin-gravity-forms'
import Footer from '../components/footer'

import qrCode from "../images/Southern360_Contact.jpg"

const ContactForm = () => {
  const data = useStaticQuery(graphql`
  query formQuery {
    wpGfForm(databaseId: { eq: 1 }) {
      ...GravityFormFields
    }
  }
`)
  // <GravityFormForm data={data} />

  return (
      <Layout pageTitle='Contact Form' id="reach">
          <div className="left">
            <h1>Get a Digital Copy of the Southern 360 Solutions Map</h1>
            <p>Submit your information here to receive a copy of the reports and solutions that Southern 360 has to offer for your facility type.</p>
          </div>
          <div className="right">
            <h2 className="h1">Please take a few minutes to tell us about you</h2>
            <br></br>
            <div style={{textAlign:'center'}} >
              <img src={qrCode} alt="" style={{maxWidth:"250px"}}/>
              <p>southerncompany.com/southern360</p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer  nextBottomSlideBtn={""} nextBottomSlideBtnLink={""} id="partHome"/>
          </div>
      </Layout>
    )
}

export default ContactForm