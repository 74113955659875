import * as React from 'react'
import '../styles/style.scss'
import Footer from './footer'



const Layout = ({ pageTitle, children }) => {
    return (
      <div className="page contactPage">
        <title>{pageTitle}</title>
        <main>
          {children}
        </main>
        <Footer />
      </div>
    )
  }
  export default Layout